.examples-page {
  padding: 80px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.examples-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.example-card {
  background: var(--bg-secondary);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
  overflow: hidden;
  transition: all 0.3s ease;
}

.example-card:hover {
  transform: translateY(-4px);
  box-shadow: var(--shadow-lg);
}

.example-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.example-card:hover img {
  transform: scale(1.05);
}

.example-content {
  padding: 24px;
}

.example-content h3 {
  font-size: 20px;
  color: var(--text-primary);
  margin: 0 0 12px 0;
}

.example-content p {
  color: var(--text-secondary);
  margin: 0 0 16px 0;
  line-height: 1.6;
}

.example-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  padding: 4px 12px;
  background: var(--bg-primary);
  color: var(--primary-color);
  border-radius: 16px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.tag:hover {
  background: var(--primary-color);
  color: white;
}

@media (max-width: 768px) {
  .examples-grid {
    grid-template-columns: 1fr;
  }

  .example-card img {
    height: 180px;
  }

  .example-content {
    padding: 20px;
  }

  .example-content h3 {
    font-size: 18px;
  }
} 