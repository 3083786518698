.search-container {
  max-width: 800px;
  margin: 40px auto;
  text-align: center;
}

.search-header {
  margin-bottom: 40px;
}

.search-header h1 {
  font-size: 2.5rem;
  color: #1a1a1a;
  margin-bottom: 16px;
  font-weight: 700;
  background: linear-gradient(135deg, #1890ff 0%, #722ed1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.search-header p {
  font-size: 1.1rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
}

.search-input-wrapper {
  position: relative;
  margin: 30px auto;
  max-width: 600px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background: white;
  padding: 8px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.search-input-wrapper:focus-within {
  box-shadow: 0 6px 24px rgba(24, 144, 255, 0.15);
  transform: translateY(-2px);
}

.search-input {
  flex: 1;
  border: none;
  padding: 16px 24px;
  font-size: 1.1rem;
  background: transparent;
  color: #333;
  outline: none;
}

.search-input::placeholder {
  color: #999;
  opacity: 0.8;
}

.search-button {
  background: linear-gradient(135deg, var(--gradient-start), var(--gradient-end));
  color: white;
  border: none;
  padding: 16px 32px;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  overflow: hidden;
}

.search-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(255,255,255,0.1), rgba(255,255,255,0));
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.search-button:hover::before {
  transform: translateX(0);
}

.search-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(24,144,255,0.3);
}

.search-button:active:not(:disabled) {
  transform: translateY(1px);
}

.search-button:disabled {
  background: #d9d9d9;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.search-button .icon {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
}

.search-button:hover .icon {
  transform: rotate(180deg);
}

.search-button.loading .icon {
  animation: spin 1s linear infinite;
}

/* 添加动画效果 */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.search-container {
  animation: fadeIn 0.6s ease-out;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .search-container {
    padding: 0 20px;
  }

  .search-header h1 {
    font-size: 2rem;
  }

  .search-input-wrapper {
    flex-direction: column;
    gap: 12px;
    padding: 12px;
  }

  .search-button {
    width: 100%;
    justify-content: center;
  }
}

.search-options {
  display: flex;
  gap: 20px;
  margin-top: 16px;
  justify-content: center;
}

.search-option {
  display: flex;
  align-items: center;
  gap: 10px;
  background: white;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.search-option label {
  font-size: 14px;
  color: #666;
  white-space: nowrap;
}

.search-option select {
  padding: 4px 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  background: white;
  cursor: pointer;
  outline: none;
  min-width: 80px;
}

.search-option select:focus {
  border-color: #1890ff;
}

@media (max-width: 768px) {
  .search-options {
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
  }

  .search-option {
    justify-content: space-between;
  }
}

.search-option input[type="number"] {
  width: 80px;
  padding: 4px 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  background: white;
  outline: none;
}

.search-option input[type="number"]:focus {
  border-color: #1890ff;
}

.search-option input[type="number"]::-webkit-inner-spin-button,
.search-option input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
  height: 20px;
}

.search-option.custom {
  position: relative;
}

.search-option.custom .error-tip {
  position: absolute;
  bottom: -20px;
  left: 0;
  font-size: 12px;
  color: #ff4d4f;
}

.image-count-inputs {
  display: flex;
  gap: 8px;
  align-items: center;
}

.toggle-input-type {
  padding: 4px 8px;
  font-size: 12px;
  background: #f0f0f0;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  color: #666;
  cursor: pointer;
  transition: all 0.3s;
}

.toggle-input-type:hover:not(:disabled) {
  background: #e6f7ff;
  border-color: #1890ff;
  color: #1890ff;
}

.toggle-input-type:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.image-count input[type="number"],
.image-count select {
  width: 120px;
}

@media (max-width: 768px) {
  .image-count-inputs {
    width: 100%;
  }
  
  .image-count input[type="number"],
  .image-count select {
    flex: 1;
  }
  
  .toggle-input-type {
    white-space: nowrap;
  }
} 