.seo-content {
  margin: 60px auto;
  max-width: 1200px;
  padding: 0 20px;
}

.seo-content section {
  margin-bottom: 60px;
}

.seo-content h2 {
  text-align: center;
  font-size: 32px;
  color: #333;
  margin-bottom: 40px;
  font-weight: 600;
}

/* FAQ 样式 */
.faq-list {
  display: grid;
  gap: 24px;
  max-width: 800px;
  margin: 0 auto;
}

.faq-item {
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
  transition: transform 0.3s ease;
}

.faq-item:hover {
  transform: translateY(-2px);
}

.faq-item h3 {
  color: #1890ff;
  margin: 0 0 12px 0;
  font-size: 18px;
}

.faq-item p {
  color: #666;
  line-height: 1.6;
  margin: 0;
}

/* 用户评价样式 */
.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
}

.testimonial-card {
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
}

.testimonial-text {
  color: #333;
  font-style: italic;
  margin-bottom: 20px;
  line-height: 1.6;
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 12px;
}

.author-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #1890ff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

.author-info h4 {
  margin: 0;
  color: #333;
}

.author-info p {
  margin: 4px 0 0;
  color: #666;
  font-size: 14px;
}

/* 功能特点样式 */
.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
}

.feature-card {
  background: white;
  padding: 24px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-4px);
}

.feature-icon {
  font-size: 32px;
  margin-bottom: 16px;
}

.feature-card h3 {
  color: #333;
  margin: 0 0 12px 0;
}

.feature-card p {
  color: #666;
  line-height: 1.6;
  margin: 0;
}

/* 使用场景样式 */
.use-cases-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
}

.use-case-card {
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
}

.use-case-card h3 {
  color: #1890ff;
  margin: 0 0 16px 0;
}

.use-case-card p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 16px;
}

.use-case-card ul {
  margin: 0;
  padding-left: 20px;
  color: #666;
}

.use-case-card li {
  margin-bottom: 8px;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .seo-content {
    margin: 40px auto;
  }

  .seo-content h2 {
    font-size: 24px;
    margin-bottom: 24px;
  }

  .testimonials-grid,
  .features-grid,
  .use-cases-grid {
    grid-template-columns: 1fr;
  }
} 