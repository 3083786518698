.ranking-list-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.ranking-list-content {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 40px;
  margin-top: 40px;
}

.rankings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}

.ranking-card {
  background: var(--bg-secondary);
  border-radius: var(--radius-lg);
  padding: 24px;
  box-shadow: var(--shadow-sm);
  transition: all 0.3s ease;
  text-decoration: none;
  color: var(--text-primary);
}

.ranking-card:hover {
  transform: translateY(-4px);
  box-shadow: var(--shadow-md);
}

.ranking-card h3 {
  margin: 0 0 12px 0;
  font-size: 18px;
  color: var(--text-primary);
}

.ranking-card p {
  margin: 0 0 16px 0;
  color: var(--text-secondary);
  font-size: 14px;
  line-height: 1.5;
}

.ranking-meta {
  display: flex;
  gap: 16px;
  color: var(--text-secondary);
  font-size: 13px;
}

.popular-rankings {
  background: var(--bg-secondary);
  border-radius: var(--radius-lg);
  padding: 24px;
  box-shadow: var(--shadow-sm);
}

.popular-rankings h2 {
  margin: 0 0 20px 0;
  font-size: 20px;
  color: var(--text-primary);
}

.popular-ranking-item {
  display: block;
  padding: 12px 0;
  border-bottom: 1px solid var(--border-color);
  text-decoration: none;
  color: var(--text-primary);
}

.popular-ranking-item:last-child {
  border-bottom: none;
}

.popular-ranking-item h4 {
  margin: 0 0 8px 0;
  font-size: 15px;
}

.ranking-stats {
  display: flex;
  gap: 12px;
  color: var(--text-secondary);
  font-size: 13px;
}

.load-more-button {
  display: block;
  width: 100%;
  padding: 12px;
  margin-top: 32px;
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-sm);
  color: var(--text-primary);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.load-more-button:hover:not(:disabled) {
  background: var(--bg-primary);
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.load-more-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@media (max-width: 1024px) {
  .ranking-list-content {
    grid-template-columns: 1fr;
  }
  
  .sidebar {
    order: -1;
  }
}

@media (max-width: 768px) {
  .rankings-grid {
    grid-template-columns: 1fr;
  }
  
  .ranking-list-page {
    padding: 20px;
  }
} 