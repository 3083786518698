.template-selector {
  margin: 40px auto;
  max-width: 1200px;
  padding: 20px;
}

.template-selector h2 {
  text-align: center;
  font-size: 24px;
  color: var(--text-primary);
  margin-bottom: 30px;
  font-weight: 600;
}

.template-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px;
  padding: 20px;
}

.template-item {
  position: relative;
  border: 2px solid transparent;
  border-radius: var(--radius-lg);
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  background: var(--bg-secondary);
  box-shadow: var(--shadow-sm);
  cursor: pointer;
  transform-origin: center;
  outline: none;
}

.template-item:hover,
.template-item:focus-visible {
  transform: translateY(-4px);
  box-shadow: var(--shadow-lg);
}

.template-item.selected {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(24,144,255,0.2);
}

.template-item.preview .template-preview img {
  transform: scale(1.05);
}

.template-preview {
  position: relative;
  width: 100%;
  padding-top: 66.67%;
  overflow: hidden;
  background: var(--bg-primary);
}

.template-preview img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.template-info {
  padding: 16px;
  background: var(--bg-secondary);
}

.template-info h3 {
  margin: 0 0 8px 0;
  font-size: 18px;
  color: var(--text-primary);
  font-weight: 600;
}

.template-info p {
  margin: 0;
  font-size: 14px;
  color: var(--text-secondary);
  line-height: 1.5;
}

.template-badge {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 4px 12px;
  background: var(--primary-color);
  color: white;
  font-size: 12px;
  border-radius: var(--radius-sm);
  font-weight: 500;
  animation: badgeFadeIn 0.3s ease forwards;
}

.template-hover-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(to top, rgba(0,0,0,0.9), transparent);
  color: white;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  backdrop-filter: blur(4px);
}

.template-item:hover .template-hover-info,
.template-item.preview .template-hover-info {
  opacity: 1;
  transform: translateY(0);
}

.template-hover-info h4 {
  margin: 0 0 12px 0;
  font-size: 16px;
  font-weight: 500;
}

.template-hover-info ul {
  margin: 0;
  padding: 0 0 0 16px;
  font-size: 14px;
  opacity: 0.9;
}

.template-hover-info li {
  margin: 4px 0;
}

.template-actions {
  display: flex;
  gap: 8px;
  padding: 12px 16px;
  background: var(--bg-secondary);
  border-top: 1px solid var(--border-color);
}

.template-actions button {
  flex: 1;
  padding: 8px 12px;
  border: none;
  border-radius: var(--radius-sm);
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.preview-button {
  background: var(--bg-primary);
  color: var(--text-secondary);
}

.preview-button:hover {
  background: var(--bg-primary);
  color: var(--text-primary);
}

.select-button {
  background: var(--primary-color);
  color: white;
}

.select-button:hover {
  background: var(--primary-hover);
  transform: translateY(-1px);
}

.select-button.selected {
  background: var(--bg-primary);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

@keyframes badgeFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .template-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 16px;
    padding: 10px;
  }

  .template-info h3 {
    font-size: 16px;
  }

  .template-info p {
    font-size: 13px;
  }

  .template-actions {
    padding: 8px 12px;
  }

  .template-actions button {
    padding: 6px 10px;
    font-size: 12px;
  }
}

/* 添加键盘焦点样式 */
.template-item:focus-visible {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* 添加动画效果 */
.template-item {
  animation: templateFadeIn 0.5s ease forwards;
}

@keyframes templateFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} 