.pricing-page {
  padding: 80px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.pricing-card {
  position: relative;
  background: var(--bg-secondary);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-sm);
  padding: 32px;
  transition: all 0.3s ease;
  text-align: center;
}

.pricing-card:hover {
  transform: translateY(-4px);
  box-shadow: var(--shadow-lg);
}

.pricing-card.popular {
  border: 2px solid var(--primary-color);
}

.popular-badge {
  position: absolute;
  top: -12px;
  right: 24px;
  background: var(--primary-color);
  color: white;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
}

.pricing-card h3 {
  font-size: 24px;
  color: var(--text-primary);
  margin: 0 0 24px 0;
}

.price {
  margin-bottom: 24px;
}

.currency {
  font-size: 24px;
  color: var(--text-primary);
  vertical-align: top;
}

.amount {
  font-size: 48px;
  color: var(--text-primary);
  font-weight: 700;
  line-height: 1;
}

.period {
  font-size: 16px;
  color: var(--text-secondary);
}

.features {
  list-style: none;
  padding: 0;
  margin: 0 0 32px 0;
  text-align: left;
}

.features li {
  color: var(--text-secondary);
  padding: 8px 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.features li::before {
  content: "✓";
  color: var(--primary-color);
  font-weight: bold;
}

.select-plan {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: var(--radius-sm);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.select-plan.primary {
  background: var(--primary-color);
  color: white;
}

.select-plan.secondary {
  background: var(--bg-primary);
  color: var(--text-primary);
}

.select-plan:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

@media (max-width: 768px) {
  .pricing-grid {
    grid-template-columns: 1fr;
  }

  .pricing-card {
    padding: 24px;
  }

  .amount {
    font-size: 36px;
  }
} 