.language-selector {
  position: relative;
  z-index: 1000;
}

.language-selector-trigger {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: white;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-sm);
  color: var(--text-primary);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 120px;
}

.language-selector-trigger:hover {
  border-color: var(--primary-color);
  background: var(--bg-secondary);
}

.language-flag {
  font-size: 16px;
}

.language-name {
  flex: 1;
  text-align: left;
}

.arrow-icon {
  font-size: 10px;
  transition: transform 0.3s ease;
}

.arrow-icon.open {
  transform: rotate(180deg);
}

.language-dropdown {
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  background: white;
  border-radius: var(--radius-sm);
  box-shadow: var(--shadow-md);
  min-width: 160px;
  overflow: hidden;
  animation: dropdownFadeIn 0.2s ease;
}

.language-option {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 10px 16px;
  border: none;
  background: transparent;
  color: var(--text-primary);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.language-option:hover {
  background: var(--bg-primary);
}

.language-option.active {
  background: var(--bg-primary);
  color: var(--primary-color);
  font-weight: 500;
}

.check-icon {
  margin-left: auto;
  color: var(--primary-color);
  font-size: 14px;
}

@keyframes dropdownFadeIn {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 响应式设计 */
@media (max-width: 768px) {
  .language-selector {
    position: static;
  }

  .language-dropdown {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: var(--radius-lg) var(--radius-lg) 0 0;
    box-shadow: var(--shadow-lg);
    animation: slideUp 0.3s ease;
  }

  .language-option {
    padding: 16px;
    font-size: 16px;
  }

  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
} 