.generator-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.input-section {
  text-align: center;
  margin-bottom: 60px;
}

.input-section h1 {
  font-size: 2.5rem;
  color: var(--text-primary);
  margin-bottom: 16px;
}

.input-section p {
  font-size: 1.1rem;
  color: var(--text-secondary);
  margin-bottom: 40px;
}

.input-container {
  max-width: 800px;
  margin: 0 auto;
  background: var(--bg-secondary);
  padding: 24px;
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-md);
}

.input-container textarea {
  width: 100%;
  min-height: 120px;
  padding: 16px;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-sm);
  font-size: 16px;
  resize: vertical;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.input-container textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(24,144,255,0.1);
}

.settings-container {
  display: flex;
  gap: 20px;
  margin-bottom: 24px;
}

.setting-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.setting-item label {
  font-size: 14px;
  color: var(--text-secondary);
}

.setting-item input,
.setting-item select {
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: var(--radius-sm);
  font-size: 14px;
}

.toggle-input-type {
  margin-top: 8px;
  padding: 4px 12px;
  font-size: 12px;
  color: var(--text-secondary);
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-sm);
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-input-type:hover:not(:disabled) {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.generate-button {
  width: 100%;
  padding: 16px;
  background: linear-gradient(135deg, var(--gradient-start), var(--gradient-end));
  color: white;
  border: none;
  border-radius: var(--radius-sm);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.generate-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(24,144,255,0.3);
}

.generate-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.template-section {
  margin: 40px 0;
}

.template-section h2 {
  text-align: center;
  font-size: 2rem;
  color: var(--text-primary);
  margin-bottom: 30px;
}

.result-section {
  margin: 40px 0;
}

@media (max-width: 768px) {
  .settings-container {
    flex-direction: column;
  }

  .input-section h1 {
    font-size: 2rem;
  }

  .input-section p {
    font-size: 1rem;
  }

  .input-container {
    padding: 16px;
  }

  .input-container textarea {
    font-size: 14px;
  }

  .generate-button {
    padding: 12px;
    font-size: 14px;
  }
} 