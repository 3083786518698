.social-share {
  margin: 20px 0;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 8px;
}

.social-share h3 {
  margin: 0 0 15px 0;
  color: #333;
}

.share-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.share-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: opacity 0.3s;
}

.share-buttons button:hover {
  opacity: 0.8;
}

.weibo {
  background-color: #e6162d;
  color: white;
}

.twitter {
  background-color: #1da1f2;
  color: white;
}

.facebook {
  background-color: #1877f2;
  color: white;
}

.copy {
  background-color: #52c41a;
  color: white;
} 