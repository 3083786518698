.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  z-index: 1000;
}

.navbar-content {
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  font-weight: 600;
  color: var(--text-primary);
  text-decoration: none;
}

.logo-icon {
  font-size: 24px;
}

.logo-text {
  background: linear-gradient(135deg, var(--gradient-start), var(--gradient-end));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navbar-menu {
  display: flex;
  gap: 32px;
}

.menu-item {
  color: var(--text-secondary);
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
}

.menu-item::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(135deg, var(--gradient-start), var(--gradient-end));
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.menu-item:hover {
  color: var(--text-primary);
}

.menu-item:hover::after {
  transform: scaleX(1);
}

.menu-item.active {
  color: var(--primary-color);
}

.menu-item.active::after {
  transform: scaleX(1);
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.try-button {
  padding: 8px 20px;
  background: linear-gradient(135deg, var(--gradient-start), var(--gradient-end));
  color: white;
  border: none;
  border-radius: var(--radius-sm);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.try-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(24, 144, 255, 0.3);
}

@media (max-width: 768px) {
  .navbar-menu {
    display: none;
  }
  
  .navbar-right {
    gap: 8px;
  }
  
  .try-button {
    padding: 6px 12px;
    font-size: 13px;
  }
} 