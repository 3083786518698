.ranking-container {
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden;
}

.ranking-item {
  transition: all 0.3s ease;
}

.ranking-item:hover {
  transform: translateY(-2px);
}

.ranking-item-header {
  display: flex;
  gap: 16px;
  align-items: center;
}

.content {
  flex: 1;
  min-width: 0;
}

.item-image {
  flex-shrink: 0;
}

.image-thumbnails {
  display: flex;
  gap: 8px;
  margin-top: 12px;
  padding: 4px;
  overflow-x: auto;
  scrollbar-width: thin;
}

.image-thumbnails::-webkit-scrollbar {
  height: 4px;
}

.image-thumbnails::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

.image-thumbnails::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

.thumbnail {
  flex: 0 0 60px;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.thumbnail:hover {
  opacity: 0.9;
}

.thumbnail.active {
  opacity: 1;
  border-color: #1890ff;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.actions {
  display: flex;
  gap: 16px;
  margin-top: 24px;
  justify-content: center;
}

.action-button {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.action-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.action-button:not(:disabled):hover {
  transform: translateY(-2px);
}

.share-button {
  background: linear-gradient(135deg, #1890ff, #096dd9);
  color: white;
}

.share-button:hover:not(:disabled) {
  box-shadow: 0 4px 12px rgba(24, 144, 255, 0.3);
}

.share-info {
  margin-top: 24px;
  padding: 16px;
  background: var(--bg-secondary);
  border-radius: 8px;
  text-align: center;
}

.share-url {
  display: flex;
  gap: 8px;
  margin-top: 12px;
}

.share-url input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
  color: var(--text-primary);
  background: var(--bg-primary);
}

.share-url button {
  padding: 8px 16px;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.share-url button:hover {
  background: var(--primary-hover);
}

@media (max-width: 768px) {
  .actions {
    flex-direction: column;
  }

  .share-url {
    flex-direction: column;
  }

  .share-url input {
    text-align: center;
  }
} 