.share-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.share-header {
  text-align: center;
  margin-bottom: 40px;
  padding: 40px;
  background: var(--bg-secondary);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-md);
}

.share-header h1 {
  font-size: 2.5rem;
  color: var(--text-primary);
  margin-bottom: 16px;
  line-height: 1.2;
  background: linear-gradient(135deg, var(--gradient-start), var(--gradient-end));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.share-meta {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin-bottom: 12px;
}

.share-description {
  color: var(--text-secondary);
  font-size: 1.1rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

.share-content {
  margin: 40px 0;
  padding: 32px;
  background: var(--bg-secondary);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-md);
}

.share-footer {
  text-align: center;
  margin-top: 60px;
  padding: 40px;
  background: var(--bg-secondary);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-md);
}

.share-footer p {
  color: var(--text-secondary);
  margin-bottom: 16px;
}

.create-own {
  display: inline-block;
  padding: 12px 24px;
  background: linear-gradient(135deg, var(--gradient-start), var(--gradient-end));
  color: white;
  text-decoration: none;
  border-radius: var(--radius-sm);
  font-weight: 500;
  transition: all 0.3s ease;
}

.create-own:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(24,144,255,0.3);
}

.share-page.loading,
.share-page.error {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.back-home {
  display: inline-block;
  margin-top: 20px;
  padding: 8px 16px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--radius-sm);
  text-decoration: none;
  transition: all 0.3s ease;
}

.back-home:hover {
  background: var(--primary-color);
  color: white;
}

/* 添加动画效果 */
.share-page {
  animation: fadeIn 0.6s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 响应式设计 */
@media (max-width: 768px) {
  .share-page {
    padding: 20px;
  }

  .share-header {
    padding: 24px;
  }

  .share-header h1 {
    font-size: 2rem;
  }

  .share-description {
    font-size: 1rem;
  }

  .share-content {
    padding: 16px;
  }

  .share-footer {
    padding: 24px;
  }
}

/* 打印样式 */
@media print {
  .share-footer,
  .create-own {
    display: none;
  }

  .share-content {
    box-shadow: none;
    padding: 0;
  }

  .share-header {
    box-shadow: none;
    padding: 0;
  }
} 